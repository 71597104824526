import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import YouTube from "../components/youtube";

export default () => {

  return (
    <Layout>
    <div style={{ 'position': 'relative' }}>
      <Link to='/oer-inventionen-plus-one-kammermusik-mit-johann-sebastian-bach' className="closeX"><span>X</span></Link>
      <h2>Beispielaufnahmen zu den <i>Inventionen plus One</i></h2>
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <div>
      <Link to="/oer-inventionen-plus-one-kammermusik-mit-johann-sebastian-bach"><img src="/Images/kaiser_openbookInventionen.jpg" alt="" /></Link>
    </div>
    <p>
      Ulrich Kaiser, <span class="i">Inventionen plus One. Kammermusik mit Johann Sebastian Bach</span> (=&nbsp;OpenBook&nbsp;11), Karlsfeld 2015.<br />
      <a href="http://www.scribus.net/canvas/Scribus" target="_blank"><img src="/Images/scribusLogo.png" /> </a>&nbsp;<img src="/Images/CC_BY-SA.png" /><br />
      Dieses OpenBook wurde mit der Opensource-Software <a href="http://www.scribus.net/canvas/Scribus" target="_blank">Scribus</a> unter
      <a href="https://creativecommons.org/licenses/by/3.0/de/">Creative Commons BY-SA</a> veröffentlicht.        
    </p>
    <hr style={{ "margin": "40px 0" }} />
    <div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls>
          <source src="/data/audio/Inventionen/01_Invention_C-Dur.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p class="audiotext">Invention in C-Dur</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls>
          <source src="/data/audio/Inventionen/02_Invention_d-Moll.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p class="audiotext">Invention in d-Moll</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls>
          <source src="/data/audio/Inventionen/03_Invention_e-Moll.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p class="audiotext">Invention in e-Moll</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls>
          <source src="/data/audio/Inventionen/04_Invention_G-Dur.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p class="audiotext">Invention in G-Dur</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls>
          <source src="/data/audio/Inventionen/05_Invention_a-Moll.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
          <p class="audiotext">Invention in a-Moll</p>
      </div>
      <hr style={{ "margin": "40px 0" }} />
      <div>
          Violine: Teresa Allgaier, Klavier: Stefan Fuchs
      </div>
    </div>
    <Helmet>
      <title>OER | Ulrich Kaiser - Inventionen plus One (Aufnahmen)</title>
      <meta name="description" content="Hier finden Sie Beispielaufnahmen zu den Inventionen mit einer Melosdiestimme von Ulrich Kaiser." />
      <meta name="keywords" content="Inventionen, Melodiestimme, OER, Open Educational Resources, OpenBooks" />
      <meta name="copyright" content="Ulrich Kaiser, 2011" />
    </Helmet>
  </Layout>
  )
}

